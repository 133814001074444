nav {
  transition: 0.5s ease;
}

.nav-buttons {
  font-family: 'NeueMontreal';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4vh;
  line-height: 17px;
  /* identical to box height */
  padding: 2vh 2vw 0px 0px;

  transition: 0.5s ease;

  color: #000000;
}

.nav-link {
  color: #000000;
  margin-left: 6vw;
  transition: 0.5s ease;
}

.nav-link:hover {
  letter-spacing: 0.2em;
  transition: 0.5s ease;
}

.nav-link:focus {
  letter-spacing: 0.2em;
  transition: 0.5s ease;
}

.nav-link:active {
  letter-spacing: 0.2em;
  transition: 0.5s ease;
}

.nav-scrolled {
  padding: 0;
  background-color: var(--white);
  transition: 1s ease;
  border-bottom: 2px solid var(--black);
  margin-left: 2vw;
  margin-right: 2vw;
}

.nav-scrolled-btn {
  padding: 0;
  transition: 1s ease;
}

.nav-logo {
  opacity: 0;
  transition: 0.2s ease;
  
}
.nav-scrolled-logo {
  opacity: 100%;
  transition: 1s ease;
  transition-delay: 2s;
}